import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { ICompany } from '../interfaces/company.interface';
import { RestService } from './rest.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class selectedTenantService {

  private selectedTenant = new BehaviorSubject<string>(this.dataService.getLocalStorageItem('tenant_id'))

  public tenantSub = this.selectedTenant.asObservable()

  setTenantSub(value: string) {
    this.selectedTenant.next(value)
  }

  getTenantSub() {
    return this.selectedTenant.getValue()
  }

  constructor(private restService: RestService, private dataService: DataService) { }

  public getSubCompanies(tenantId: string): Observable<IRestResponse<ICompany[]>> {
    return this.restService
      .get(Environment.UrlPath + `/company/tenants/${tenantId}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      )
  }

}
